import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Loader from "./assets/images/loader.jsx";
const ConnectEnvironment = lazy(() => import("./pages/adminCenter/list/ConnectEnvironment.jsx"));
const ManageOrganization = lazy(() => import("./pages/adminCenter/list/ManageOrganization.jsx"));
const ManageEnvironment = lazy(() => import("./pages/adminCenter/list/ManageEnvironment.jsx"));
const ManageUser = lazy(() => import("./pages/adminCenter/list/ManageUser.jsx"));
const NotFound = lazy(() => import("./pages/notFound/NotFound.jsx"));
const ActionCenter = lazy(() => import("./pages/actionCenter/actionCenter.tsx").then((module) => ({ default: module.ActionCenter })));
const Login = lazy(() => import("./pages/login/login").then((module) => ({ default: module.Login })));
const AdminNav = lazy(() => import("./pages/adminCenter/AdminNav.jsx").then((module) => ({ default: module.AdminNav })));
const MyAccounts = lazy(() => import("./pages/login/MyAccounts.jsx").then((module) => ({ default: module.MyAccounts })));
const AdminDashBoard = lazy(() => import("./pages/adminCenter/AdminDashBoard.jsx").then((module) => ({ default: module.AdminDashBoard })));
const AdminManager = lazy(() => import("./pages/adminCenter/AdminManager.jsx").then((module) => ({ default: module.AdminManager })));

function AppRoutes() {
  return (
    <Router>
      <Suspense fallback={<div className="h-screen flex items-center justify-center"><Loader /></div>}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/actionCenter" element={<ActionCenter />} />
          <Route path="/profile" element={<AdminNav />}>
            <Route path="myaccount" element={<MyAccounts />} />
            <Route path="admindashboard" element={<AdminDashBoard />} />
            <Route path="adminmanager" element={<AdminManager />}>
              <Route path="manageorganization" element={<ManageOrganization />} />
              <Route path="manageuser" element={<ManageUser />} />
              <Route path="manageenvironment" element={<ManageEnvironment />} />
              <Route path="connectenvironment" element={<ConnectEnvironment />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default AppRoutes;
